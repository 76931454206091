import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export function footer() {

    window.addEventListener("load", function(){
        gsap.registerPlugin(ScrollTrigger);

        const footerEl = document.querySelector('.j-footerBottom');

        gsap.to(footerEl, {
            ease: 'none',
            scrollTrigger: {
                // markers: true,
                trigger: footerEl,
                start: 'top top',
                end: 'bottom top',
                scrub: true,
                pin: true,
                anticipatePin: 1,
                invalidateOnRefresh: true,
                // markers: true
            },
        });
        // let window_w = window.innerWidth;
        // if(window_w > 768) {

        // }
    });
}