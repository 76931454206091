import { header } from './modules/header';
import { animation } from './modules/animation';
// import { mv } from './modules/mv';
import { loading } from './modules/loading';
import { footer } from './modules/footer';
import { accordion } from './modules/accordion';
// import { side } from './modules/side';
// import { link } from './modules/link';
loading();
header();
animation();
// mv();
footer();
accordion();
// side();
// link();